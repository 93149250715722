import React, { useMemo } from "react";
import { useState, useEffect } from "react";
import {
  Select,
  TextField,
  Typography,
  useTheme,
  MenuItem,
  Button,
} from "@mui/material";
import Box from "@mui/material/Box";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";
import moment from "moment/moment";
import { Stack } from "@mui/system";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import image from ".././../Data/image";
import Utils from "../../Global/utils";
import * as CONSTANTS from "../../Common/Constants";
import api from "../../Data/api";
import { GET } from "../../Functions/apiFunction";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import logo from "../../assets/a_logo.png";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import the UTC plugin

// Extend dayjs with the UTC plugin
dayjs.extend(utc);

function SubscriptionOrders() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [orders, setorders] = useState();
  const [mainproducts, setMainproducts] = useState();
  const [pageSize, setpageSize] = useState(20);

  const dispatch = useDispatch();

  const admin = JSON.parse(sessionStorage.getItem("admin"));
  const token = `Bearer ${admin.token}`;
  const status = (id) => {
    const data = [
      {
        id: 1,
        text: "Confirmed",
      },
      {
        id: 2,
        text: "Canceled",
      },
      {
        id: 0,
        text: "Pending",
      },
    ];
    const ttl = data.filter((dt) => dt.id === id);
    return ttl[0]?.text || "N/A";
  };

  useEffect(() => {
    // Get categoriues
    const getOrders = async () => {
      const url = `${api}/get_subscription_order`;
      const products = await GET(token, url);
      setorders(products.data);
      setMainproducts(products.data);
    };
    getOrders();
  }, [token, dispatch]);

  const column = useMemo(
    () => [
      { field: "order_number", headerName: "ID", width: 120 },
      { field: "name", headerName: "Customer Name", width: 150 },
      { field: "s_phone", headerName: "Phone Number", width: 120 },
      {
        field: "qty",
        headerName: "Quantity",
        width: 100,
        renderCell: (params) => {
          const quantity = params.row.qty;
          const subscriptionType = params.row.subscription_type;
          return Utils.getUpdatedTotalQuantity(quantity, subscriptionType);
        },
      },
      {
        field: "title",
        headerName: "Product",
        width: 200,
        valueGetter: (params) =>
          params.row.subscription_type !== null
            ? params.row.title
            : JSON.parse(params.row.product_detail)
                ?.map((product) => product.product_title)
                .join(", "),
      },
      {
        field: "order_amount",
        headerName: "Amount",
        width: 100,
        renderCell: (params) => (
          <p>{params.row?.order_amount?.toFixed(2) || "0.00"}</p>
        ),
      },
      // {
      //   field: "image",
      //   headerName: "Image",
      //   width: 100,
      //   height: 100,
      //   renderCell: (params) =>
      //     params.row.product_image != null ? (
      //       <div
      //         style={{
      //           display: "flex",
      //           justifyContent: "center",
      //           width: "100%",
      //         }}
      //       >
      //         <img
      //           src={`${image}/${params.row.product_image}`}
      //           alt={params.row.product_image}
      //           height={"45px"}
      //         />
      //       </div>
      //     ) : (
      //       <i class="fa-regular fa-image" style={{ fontSize: "22px" }}></i>
      //     ),
      // },
      {
        field: "created_at",
        headerName: "Ordered Date",
        width: 100,
        renderCell: (params) =>
          params.row.created_at
            ? moment.utc(params.row?.created_at).local().format("DD-MM-YYYY")
            : "--",
      },
      {
        field: "order_type",
        headerName: "Order Type",
        width: 140,
        renderCell: (params) => {
          let orderText = Utils.getOrderType(params.row.order_type);
          return <p>{orderText}</p>;
        },
      },
      {
        field: "status",
        headerName: "Order Status",
        width: 100,
        renderCell: (params) => <p>{status(params.row.status)}</p>,
      },
      {
        field: "order_status",
        headerName: "Subscription Status",
        width: 130,
        renderCell: (params) =>
          params.row.subscription_type !== null ? (
            <p>{params.row.order_status === 0 ? "Active" : "Paused"}</p>
          ) : (
            <p>{params.row.order_status === 0 ? "Active" : "N/A"}</p>
          ),
      },
      {
        field: "",
        headerName: "Subscription Type",
        width: 140,
        renderCell: (params) => {
          let subscriptionText = Utils.getSubscriptionType(
            params.row.subscription_type
          );
          return <p>{subscriptionText}</p>;
        },
      },
      {
        field: "start_date",
        headerName: "Start Date",
        width: 100,
        renderCell: (params) =>
          params.row.start_date
            ? dayjs(params.row.start_date).utc().local().format("DD-MM-YYYY")
            : "--",
      },
      {
        field: "end_date",
        headerName: "End Date",
        width: 100,
        renderCell: (params) =>
          params.row.end_date
            ? dayjs(params.row.end_date).utc().local().format("DD-MM-YYYY")
            : "--",
      },
      // {
      //   field: "wallet_amount",
      //   headerName: "Wallet Amount",
      //   width: 100,
      //   renderCell: (params) => (
      //     <p>{params.row?.wallet_amount?.toFixed(2) || "0.00"}</p>
      //   ),
      // },
      {
        field: "total_deliveries",
        headerName: "Total No. of Deliveries",
        width: 100,
      },
      {
        field: "deliveries_left",
        headerName: "No. of Deliveries left",
        width: 100,
      },
      {
        field: "delivered",
        headerName: "No. of Deliveries Completed",
        width: 100,
        renderCell: (params) => {
          // Check if the total deliveries and delivered match
          const isEqual = params.row.total_deliveries === params.row.delivered;
          return (
            <div
              style={{
                color: isEqual ? "green" : "red",
              }}
            >
              {params.value}
            </div>
          );
        },
      },
      // {
      //   field: "end_date",
      //   headerName: "End Date",
      //   width: 100,
      //   renderCell: (params) =>
      //     params.row.end_date
      //       ? moment.utc(params.row.end_date).local().format("DD-MM-YYYY")
      //       : "--",
      // },
      { field: "pincode", headerName: "Pincode", width: 100 },
      { field: "payment_id", headerName: "Payment ID", width: 150 },
      {
        field: "updated_at",
        headerName: "Last Update",
        width: 220,
        renderCell: (params) =>
          moment
            .utc(params.row.updated_at)
            .local()
            .format("DD-MM-YYYY HH:mm:ss"),
      },
      {
        field: "Action",
        headerName: "Action",
        width: 100,
        renderCell: (params) => (
          <button
            class="updateBtn"
            onClick={() => {
              navigate(
                `/order/${params.row.id}/?subscription_type=${
                  params.row.subscription_type !== null
                }`
              );
            }}
          >
            <i class="fa-regular fa-eye"></i>
          </button>
        ),
      },
    ],
    [navigate]
  );

  const exportToCSV = () => {
    // Prepare the headers and data
    const headers = [
      "S.No",
      "ID",
      "Customer Name",
      "Phone Number",
      "Quantity",
      "Product",
      "Amount",
      "Ordered Date",
      "Order Type",
      "Order Status",
      "Subscription Status",
      "Subscription Type",
      "Start Date",
      "Total No. of Deliveries",
      "No. of Deliveries Left",
      "No. of Deliveries Completed",
      "Pincode",
      "Payment ID",
      "Last Update",
    ];

    const reversedReports = [...orders].reverse();

    const csvData = reversedReports.map((row, index) => {
      const rowCopy = { ...row };

      // Update the qty property on the copy, not the original row
      rowCopy.qty = Utils.getUpdatedTotalQuantity(
        row.qty,
        row.subscription_type
      );
      return [
        index + 1,
        row.order_number,
        row.name,
        row.s_phone,
        rowCopy.qty,
        row.subscription_type !== null
          ? row.title
          : JSON.parse(row.product_detail)
              ?.map((product) => product.product_title)
              .join(", "),
        row?.order_amount?.toFixed(2) || "0.00",
        moment.utc(row?.created_at).local().format("DD-MM-YYYY"),
        Utils.getOrderType(row.order_type),
        status(row.status),
        row.subscription_type !== null
          ? row.order_status === 0
            ? "Active"
            : "Paused"
          : row.order_status === 0
          ? "Active"
          : "N/A",
        Utils.getSubscriptionType(row.subscription_type),
        moment.utc(row.start_date).local().format("DD-MM-YYYY"),
        row.total_deliveries,
        row.deliveries_left,
        row.delivered,
        row.pincode,
        row.payment_id,
        moment.utc(row.updated_at).local().format("DD-MM-YYYY HH:mm:ss"),
      ];
    });

    // Create worksheet and workbook
    const workbook = XLSX.utils.book_new();

    // Prepare the data for the worksheet
    const tempData = [headers, ...csvData];

    // Convert tempData to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(tempData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Subscription Orders");

    // Set the filename and download
    XLSX.writeFile(
      workbook,
      `Subscription Orders_${moment
        .utc(new Date())
        .local()
        .format("DD-MM-YYYY")}.csv`
    );
  };

  const exportToPDF = () => {
    // const dateRange = `Date Range: ${startDate} to ${endDate}`;
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "mm",
      format: "a3",
    });

    // Add the header text
    doc.setFontSize(18);
    const headerText = "Subscription Orders";
    const headerX =
      (doc.internal.pageSize.getWidth() - doc.getTextWidth(headerText)) / 2;
    doc.text(headerText, headerX, 20);

    // Load the logo and add it to the document
    Utils.getBase64FromImage(logo, (base64Logo) => {
      const logoWidth = CONSTANTS.IMAGE_OPTION.logoWidth;
      const logoHeight = CONSTANTS.IMAGE_OPTION.logoHeight;
      const pageWidth = doc.internal.pageSize.getWidth();
      doc.addImage(
        base64Logo,
        "PNG",
        pageWidth - logoWidth - 10,
        10,
        logoWidth,
        logoHeight
      );

      // Set smaller font size for the date range text below header
      doc.setFontSize(12);

      // Define table headers with column names and configure column width
      const tableColumn = [
        { header: "S.No", dataKey: "sno" },
        { header: "ID", dataKey: "order_ID" },
        { header: "Customer Name", dataKey: "name" },
        { header: "Phone Number", dataKey: "phe_no" },
        { header: "Qty", dataKey: "qty" },
        { header: "Product", dataKey: "product" },
        { header: "Amount", dataKey: "amt" },
        { header: "Order Date", dataKey: "order_date" },
        { header: "Order Type", dataKey: "order_type" },
        { header: "Order Status", dataKey: "order_status" },
        { header: "Subscription Status", dataKey: "sub_status" },
        { header: "Subscription Type", dataKey: "sub_type" },
        { header: "Start Date", dataKey: "start_date" },
        { header: "Total No. of Deliveries", dataKey: "no_of_deliveries" },
        { header: "No. of Deliveries Left", dataKey: "no_of_deliveries_left" },
        {
          header: "No. of Deliveries Completed",
          dataKey: "no_of_deliveries_completed",
        },
        { header: "Pincode", dataKey: "pincode" },
        { header: "Payment ID", dataKey: "payment_id" },
        { header: "Last Update", dataKey: "last_update" },
      ];

      const reversedReports = [...orders].reverse();

      // Map table rows and format data as needed
      const tableRows = reversedReports.map((row, index) => ({
        sno: index + 1,
        order_ID: row.order_number,
        name: row.name,
        phe_no: row.s_phone,
        qty: Utils.getUpdatedTotalQuantity(row.qty, row.subscription_type),
        product:
          row.subscription_type !== null
            ? row.title
            : JSON.parse(row.product_detail)
                ?.map((product) => product.product_title)
                .join(", "),
        amt: row?.order_amount?.toFixed(2) || "0.00",
        order_date: moment.utc(row?.created_at).local().format("DD-MM-YYYY"),
        order_type: Utils.getOrderType(row.order_type),
        order_status: status(row.status),
        sub_status:
          row.subscription_type !== null
            ? row.order_status === 0
              ? "Active"
              : "Paused"
            : row.order_status === 0
            ? "Active"
            : "N/A",
        sub_type: Utils.getSubscriptionType(row.subscription_type),
        start_date: moment.utc(row.start_date).local().format("DD-MM-YYYY"),
        no_of_deliveries: row.total_deliveries,
        no_of_deliveries_left: row.deliveries_left,
        no_of_deliveries_completed: row.delivered,
        pincode: row.pincode,
        payment_id: row.payment_id,
        last_update: moment.utc(row.updated_at).local().format("DD-MM-YYYY HH:mm:ss"),
      }));

      const tableStartY = 10 + logoHeight + 6;

      // Your table configuration remains unchanged
      doc.addFont(
        "meera-regular-unicode-font-normal.ttf",
        "meera-regular-unicode-font-normal",
        "normal"
      );
      doc.setFont("meera-regular-unicode-font-normal");
      doc.autoTable({
        head: [tableColumn.map((col) => col.header)],
        body: tableRows.map((row) => Object.values(row)),
        startY: tableStartY,
        halign: "center",
        margin: { left: 8 },
        styles: {
          fontSize: 10, // Adjust font size for table content
          cellWidth: "auto",
        },
        headStyles: {
          fillColor: [0, 162, 51], // Orange background
          textColor: [255, 255, 255], // White text
          fontSize: 10,
          halign: "center",
          valign: "middle", // Vertically aligns text in the center
          overflow: "linebreak", // Enables word wrapping
        },
        bodyStyles: {
          fontSize: 9,
          font: "meera-regular-unicode-font-normal",
          lineWidth: 0.2,
          lineColor: [0, 0, 0],
          halign: "left",
          valign: "middle",
          overflow: "linebreak",
        },
        styles: {
          fontSize: 10,
          cellPadding: 3,
          lineWidth: 0.2,
          lineColor: [0, 0, 0],
          overflow: "linebreak", // Applies word wrapping globally
        },
        columnStyles: {
          0: { cellWidth: 11 }, // S.No column
          1: { cellWidth: 15 }, // Order ID
          2: { cellWidth: 23 }, // Customer Name
          3: { cellWidth: 23 }, // Phone Number
          4: { cellWidth: 12 }, // Qty
          5: { cellWidth: 28 }, // Products
          6: { cellWidth: 20 }, // Amount
          7: { cellWidth: 20 }, // Order date
          8: { cellWidth: 16 }, // Order Type
          9: { cellWidth: 22 }, // Order Status
          10: { cellWidth: 28 }, // Subscription Status
          11: { cellWidth: 28 }, // Subscription type
          12: { cellWidth: 20 }, // Start Date
          13: { cellWidth: 20 }, // Total NO of deliveries
          14: { cellWidth: 25 }, // Total NO of deliveries Left
          15: { cellWidth: 25 }, // Total NO of deliveries Completed
          16: { cellWidth: 20 }, // PinCode
          17: { cellWidth: 27 }, // Transaction Id
          18: { cellWidth: 20 }, // Last Update
        },
        tableWidth: "wrap",
        showHead: "firstPage",
      });

      // After the table is completely generated, add the page numbers
      const totalPages = doc.internal.getNumberOfPages(); // Get total pages
      doc.setFontSize(9);
      for (let i = 1; i <= totalPages; i++) {
        doc.setPage(i); // Set the page context to the current page
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();
        const pageText = `Page ${i} of ${totalPages}`; // Format "Page X/Y"
        const marginRight = 15;
        const marginBottom = i === 1 ? 7 : 10;

        // Add page number at the bottom-right of the page
        doc.text(
          pageText,
          pageWidth - marginRight - doc.getTextWidth(pageText),
          pageHeight - marginBottom
        );
      }

      // Save the PDF
      doc.save(
        `Subscription Orders_${moment
          .utc(new Date())
          .local()
          .format("DD-MM-YYYY")}.pdf`
      );
    });
  };

  // custom toolbar
  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{ marginBottom: "1rem" }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            onClick={exportToCSV}
            disabled={orders.length === 0}
          >
            Export to CSV
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={exportToPDF}
            disabled={orders.length === 0}
          >
            Export to PDF
          </Button>
        </div>

        <button
          class="cssbuttons-io-button"
          onClick={() => {
            navigate("/neworder");
          }}
        >
          {" "}
          Add New
          <div class="icon">
            <i class="fa-regular fa-plus"></i>
          </div>
        </button>
      </GridToolbarContainer>
    );
  }
  return (
    <div style={{ height: "100%" }}>
      <Box sx={{ height: " 100%", width: "100%" }}>
        <Box className="flex items-center flex-wrap justify-between gap-4 w-100 title-menu">
          <Typography
            className=""
            variant="h2"
            component={"h2"}
            fontWeight={600}
            fontSize={"1.5rem"}
            lineHeight={"2rem"}
            sx={{
              color: theme.palette.mode === "dark" ? "#ffffffe6" : "#0e0e23",
            }}
          >
            Manage Subscription Orders
          </Typography>
          <Box
            display={"flex"}
            alignItems={"center"}
            gap={"1rem"}
            width={"32.33%"}
          >
            <TextField
              size="small"
              sx={{ width: { xs: "80%", sm: "300px", md: "500px" } }}
              id="Search"
              label="Search"
              name="Search"
              color="secondary"
              onChange={(e) => {
                e.preventDefault();
                setTimeout(() => {
                  function searchArrayByValue(arr, searchQuery) {
                    return arr
                      ?.map((obj) => ({
                        ...obj,
                        start_date_temp: new Date(obj.start_date)
                          .toISOString()
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("-"),
                        exist_order_type: obj.order_type,
                        order_type_temp:
                          obj.order_type === 1
                            ? CONSTANTS.PAYMENT_OPTIONS.PREPAID
                            : obj.order_type === 2
                            ? CONSTANTS.PAYMENT_OPTIONS.POSTPAID
                            : obj.order_type === 3
                            ? CONSTANTS.PAYMENT_OPTIONS.PAYNOW
                            : obj.order_type === 4
                            ? CONSTANTS.PAYMENT_OPTIONS.PAYLATER
                            : "",
                        exist_order_status: obj.order_status,
                        order_status_temp:
                          obj.order_status === 0
                            ? CONSTANTS.STATUSES.ACTIVE
                            : obj.order_status === 1
                            ? CONSTANTS.STATUSES.PAUSED
                            : CONSTANTS.NOT_APPLICABLE,
                        exist_status: obj.status,
                        status_temp:
                          obj.status === 1
                            ? CONSTANTS.ORDER_STATUSES.CONFIRMED
                            : obj.status === 2
                            ? CONSTANTS.ORDER_STATUSES.CANCELLED
                            : CONSTANTS.ORDER_STATUSES.PENDING,
                        exist_subscription_type: obj.subscription_type,
                        subscription_type_temp:
                          obj.subscription_type === 1
                            ? CONSTANTS.ORDER_TYPES.ONE_TIME_ORDER
                            : obj.subscription_type === 2
                            ? CONSTANTS.ORDER_TYPES.WEEKLY
                            : obj.subscription_type === 3
                            ? CONSTANTS.ORDER_TYPES.MONTHLY
                            : obj.subscription_type === 4
                            ? CONSTANTS.ORDER_TYPES.ALTERNATIVE_DAYS
                            : CONSTANTS.NOT_APPLICABLE,
                        exist_start_date: obj.start_date,
                        updated_at_temp: moment
                          .utc(obj.updated_at)
                          .local()
                          .format("DD-MM-YYYY HH:mm:ss"),
                        exist_update_at: obj.updated_at,
                      }))
                      .filter((obj) => {
                        return Object.values(obj).some((val) => {
                          if (typeof val === "string") {
                            return val
                              .toLowerCase()
                              .includes(searchQuery.toLowerCase());
                          }
                          if (typeof val === "number") {
                            return val.toString().includes(searchQuery);
                          }
                          return false;
                        });
                      })
                      .map(
                        ({
                          start_date_temp,
                          exist_order_type,
                          order_type_temp,
                          exist_order_status,
                          order_status_temp,
                          exist_status,
                          status_temp,
                          exist_subscription_type,
                          subscription_type_temp,
                          exist_start_date,
                          updated_at_temp,
                          exist_update_at,
                          ...rest
                        }) => ({
                          ...rest,
                          order_type: exist_order_type,
                          start_date: exist_start_date,
                          updated_at: exist_update_at,
                          status: exist_status,
                          order_status: exist_order_status,
                          subscription_type: exist_subscription_type,
                        })
                      );
                  }
                  setorders(
                    searchArrayByValue(
                      mainproducts,
                      e.target.value.toLowerCase()
                    )
                  );
                }, 500);
              }}
            />
          </Box>
        </Box>

        {orders ? (
          <Box
            className={`text-card-foreground shadow-sm rounded-lg height-calc p-4 xl:p-2 ${
              theme.palette.mode === "dark" ? "bg-darkcard" : "bg-card"
            }`}
            sx={{
              width: "100%",
              height: "100%",
              paddingBottom: "30px",
              "& .MuiDataGrid-root": {
                border: "none",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "none",
              },
              "& .MuiDataGrid-row": {
                fontSize: "14px",
              },
              "& .name-column--cell": {
                color: colors.greenAccent[300],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                borderBottom: "none",
                color: "#f5f5f5",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: colors.primary[0],
                borderBottom: "#000",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor:
                  theme.palette.mode === "dark" ? "#334155" : "#0e0e23",
                color: "#f5f5f5",
              },
              "& .MuiTablePagination-root": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-selectIcon": {
                color: "#f5f5f5 !important",
              },
              "& .MuiTablePagination-actions botton": {
                color: "#f5f5f5 !important",
              },
              "& .MuiCheckbox-root": {
                color: `${colors.greenAccent[200]} !important`,
              },
            }}
          >
            <DataGrid
              sx={{ fontSize: "13px" }}
              columns={column}
              rows={orders}
              components={{ Toolbar: CustomToolbar }}
              rowsPerPageOptions={[10, 20, 25, 50, 100]}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setpageSize(newPageSize)}
              localeText={{
                noRowsLabel: "No records found",
              }}
              disableVirtualization
            />
          </Box>
        ) : (
          <Stack spacing={1}>
            {/* For variant="text", adjust the height via font-size */}
            <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
            {/* For other variants, adjust the size with `width` and `height` */}

            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
            <Skeleton
              variant="rectangular"
              animation="wave"
              width={"100%"}
              height={30}
            />
          </Stack>
        )}
      </Box>
    </div>
  );
}

export default SubscriptionOrders;
